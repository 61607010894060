<template>
  <div>
    <select-server
      :id="id"
      v-model="value"
      :name="'MunicipioSelect'"
      :label="'nome'"
      :invalid="invalid"
      :title="title"
      :request="request"
      @input="$emit('input', value)"
    />
  </div>
</template>

<style lang="scss">
.remove-label .form-group {
  margin-bottom: 0;
}

.municipio-select {
  min-width: 6rem !important;
}
</style>
<script>
import api from './api'

export default {
  name: 'MunicipioSelect',
  components: {
    SelectServer: () => import('@pilar/plugins/select-server/SelectServer.vue'),
  },
  props: {
    value: {
      type: Object,
      default: () => { },
    },
    id: {
      type: [String, Number],
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    uf: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      api,
      municipios: [],
    }
  },
  methods: {
    request() {
      return new Promise((resolve, reject) => {
        let prom = api.getMunicipios()
        if (this.uf) {
          prom = api.getMunicipios(this.uf)
        }

        prom.then(payload => {
          resolve({
            data: payload.data.map(map => ({
              ibge: map.id,
              nome: map.nome,
            })),
          })
        })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>
